<template>
    <transition name="fade" mode="out-in">
        <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
        <div v-else>
            <b-list-group v-if="list && list.length" class="mt-3">
                <b-list-group-item v-for="(item, index) in list" :key="index" :href="'/client/view/' + item.CliId">
                    <b-row>
                        <b-col>
                            <div class="h5">
                                {{item.FirstName}} {{item.LastName}}  <small class="text-muted">({{item.EnrollmentTypes}})</small>
                            </div>
                        </b-col>
                        <b-col class="text-right text-muted">
                            {{item.City}}, {{item.State}}
                        </b-col>
                    </b-row>
                    <div>
                        <a v-if="!item.AcknowledgedOn" href="javascript: void(0);" @click="acknowledge(item)"><i class="far fa-circle text-danger mr-2"></i></a>
                        <i v-else-if="item.AcknowledgedOn" class="far fa-check-circle text-muted mr-2"></i>
                        <span class="text-info">{{item.CreatedBy}} <span class="text-muted">on</span> {{item.CreatedOn|longDateTime}}</span>
                    </div>
                    <p class="mt-2 ml-2 mb-0" v-html="item.Message.replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1')"></p>
                </b-list-group-item>
            </b-list-group>
            <div class="border rounded text-muted text-center" v-else>
                None Found
            </div>
        </div>
    </transition>
</template>
<script lang="ts">
    export default {
        data() {
            return {
                loading: true,
                list: [],
            };
        },
        async mounted() {
            await this.get();
            this.loading = false;
        },
        methods: {
            async acknowledge(item) {
                await this.$remote.request('ClientNotification', 'Acknowledge', { CliNotId: item.CliNotId });
                await this.get();
                this.$bvToast.toast('Notification was acknowledged', {
                    title: 'Success',
                    variant: 'success',
                    toaster: 'b-toaster-bottom-center',
                    solid: true,
                    appendToast: true,
                });
            },
            async get() {
                const response = await this.$remote.request('ClientNotification', 'GetUnAcknowledged');
                if (response) {
                    this.list = response.data;
                }
            },
        }
    }
</script>