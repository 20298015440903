<template>
    <transition name="fade" mode="out-in">
        <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
        <div v-else key="interface">
            <b-card-group deck>
                <b-card title="Welcome">
                    <p><i class="fas fa-info-circle fa-2x fa-pull-left fa-border text-primary mr-3"></i> Welcome to the case management portal.</p>
                </b-card>
                <b-card v-if="clientOverviewByStatus" no-body>
                    <b-card-title class="p-3 mb-0">Clients by Status</b-card-title>
                    <b-list-group flush>
                        <b-list-group-item v-for="(item, index) in clientOverviewByStatus" :key="index" :href="'/client/list?status=' + item.Status">
                            <div class="flex">
                                <b-badge pill class="font-weight-normal mr-2">{{item.TotalCount}}</b-badge>
                                <span :class="getStatusClass(item)">{{item.Status}}</span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-card-group>
            <b-card title="Notifications" class="mt-3">
                <notifications />
            </b-card>
            <b-card title="Open Inquiries" class="mt-3">
                <inquiries />
            </b-card>
            <terms-of-use />
        </div>
    </transition>
</template>
<script>
    import ClientBase from './mixins/ClientBase';
    import Inquiries from './subcomponents/Inquiries';
    import Notifications from './subcomponents/Notifications';
    import TermsOfUse from './subcomponents/TermsOfUse';
    export default {
        mixins: [ClientBase],
        components: {
            Inquiries,
            Notifications,
            TermsOfUse
        },
        name: 'Home',
        data() {
            return {
                loading: true,
                clientOverviewByStatus: null
            };
        },
        async mounted() {
            await this.getClientOverviewByStatus();
            this.loading = false;
        },
        methods: {
            async getClientOverviewByStatus() {
                const response = await this.$remote.request('Client', 'OverviewByStatus');
                this.clientOverviewByStatus = response;
            },
        }
    };
</script>

